/* Flot chart */

.flot-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
  &.flothcart-container-dashboard {
    min-height: 250px;
  }
}

.flot-chart {
  width: 100%;
  position: relative;
  canvas {
    position:absolute;
    top: 0;
    bottom: 0;
  }
  max-width: none;
  height: 400px;
  &.flot-chart-dashboard {
    height: 250px;
  }
}

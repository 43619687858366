/* Sidebar */

.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  font-family: $type1;
  font-weight: 300;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  box-shadow: $sidebar-box-shadow;
  -webkit-box-shadow: $sidebar-box-shadow;
  -moz-box-shadow: $sidebar-box-shadow;
  -ms-box-shadow: $sidebar-box-shadow;
  .nav-profile {
    height: $sidebar-profile-height;
    padding: 1.25rem 2.187rem;
    a {
      text-decoration: none;
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
    img {
      width: 33px;
      height: 33px;
      border-radius: 100%;
      margin-right: 1rem;
    }
    .sidebar-profile-name {
      font-size: 1rem;
      color: $sidebar-light-profile-name-color;
    }
    .sidebar-profile-designation {
      font-weight: normal;
    }
    &.dropdown {
      position: relative;
      .dropdown-toggle {
        &:after {
          position: absolute;
          right: 1.875rem;
          top: 45%;
          color: $sidebar-light-dropdown-arrow-color;
        }
      }
    }
    .dropdown-menu {
      margin-top: 0;
      padding: 0;
      width: 100%;
      .dropdown-item {
        font-size: .875rem;
        padding: .875rem 1.5rem;
      }
    }
  }

  .nav { 
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
     .nav {
       margin-bottom: 0;
       .nav-item {
         margin-bottom: 0;
       }
     }

    .nav-item {
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      font-size: $sidebar-default-font-size;
      margin-bottom: 1.5rem;
      .collapse {
        z-index: 999;
      }
      .submenu-wrapper {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        .nav-item {
          margin-bottom: 0;
        }
      }
      .sidebar-menu-title {
        color: $sidebar-light-menu-title-color;
        font-weight: bold;
        padding: 0 2.187rem;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .sidebar-icon-title {
          color: $sidebar-light-icon-title;
          margin-right: .5rem;
          font-size: 1.125rem;
          vertical-align: top;
          .rtl & {
            margin-left: .5rem;
            margin-right: 0;
          }
        }
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-top $sidebar-menu-padding-right $sidebar-menu-padding-bottom $sidebar-menu-padding-left;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        font-weight: normal;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 1.125rem;
            .rtl & {
              margin-left: 2rem;
              margin-right: 0;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }
          &.menu-arrow {
            display: inline-block;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: auto;
            margin-right: 0;
            color: $sidebar-light-menu-arrow-color;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }

            &:before {
              content: "\F140";
              font-family: "Material Design Icons";
              font-style: normal;
              display: block;
              font-size: 1rem;
              line-height: 10px;
              @include transition(all .2s ease-in);
            }
          }
        }

        .menu-title {
          color: inherit;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          &:hover {
            color: theme-color(primary);
          }
        }
        .hightlight-sidebar {
          width: 6px;
          height: 6px;
          border-radius: 100%;
          display: inline-block;
          margin-left: auto;
          margin-right: 0;
          margin-bottom: 0;
        }

        .badge {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
        &[aria-expanded="true"] {
          i {
            &.menu-arrow {
              &:before {
                @include rotate(180);
              }
            }
          }
        }
      }

      &.active {
        > .nav-link {
            background: $sidebar-light-menu-active-bg;
            position: relative;
            i,
            .menu-title,
            .menu-arrow {
              color: $sidebar-light-menu-active-color;
              font-weight: bold;
            }
        }
      }
    }

    &:not(.sub-menu) {
      margin-top: .5rem;
      > .nav-item {
        margin-top: 0;
        > .nav-link {
          margin: 0;
        }
        &:hover {
          > .nav-link {
            background: $sidebar-light-menu-hover-bg;
            color: $sidebar-light-menu-hover-color;                                    
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 0;
      margin-top:0;
      list-style: none;
      padding: $sidebar-submenu-padding;

      .nav-item {
        padding: 0;
        margin-bottom: 0;
        &::before {
          content: '';
          width: 5px;
          height: 5px;
          position: absolute;
          margin-top: 1rem;
          border-radius: 50%;
          background: #8e95aa;
        }
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
          &.active {
            color: $sidebar-light-submenu-active-color;
            background: transparent;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}
//sidebar color variation
.sidebar-dark {
  .sidebar {
    background: $sidebar-dark-bg; 

  .nav-profile {
    .sidebar-profile-name {
      color: $sidebar-dark-profile-name-color;
    }
    .sidebar-profile-designation {
      font-weight: 600;
    }
    &.dropdown {
      .dropdown-toggle {
        &:after {
          color: $sidebar-dark-dropdown-arrow-color;
        }
      }
    }
    .dropdown-menu {
      margin-top: 0;
      .dropdown-item {
        font-size: .875rem;
        padding: .875rem 1.5rem;
      }
    }
  }
    .nav {
      .nav-item {
        .sidebar-menu-title {
          color: $sidebar-dark-menu-title-color;
          .sidebar-icon-title {
            color: $sidebar-dark-icon-title;
          }
        }
        .nav-link {
          color: $sidebar-dark-menu-color;
          i {
            color: inherit;
            &.menu-icon {
              color: $sidebar-dark-menu-icon-color;
            }
          }
          .menu-title {
            color: inherit;
          }
        }
        &.active {
          > .nav-link {
            background: $sidebar-dark-menu-active-bg;
            .menu-title,
            i {
              color: $sidebar-dark-menu-active-color; 
            }
          }
        }
      }
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            > .nav-link {
              background: $sidebar-dark-menu-hover-bg;
              color: $sidebar-dark-menu-hover-color;                                    
            }
          }
        }
      }
      &.sub-menu {
        .nav-item {
          .nav-link {
            color: $sidebar-dark-submenu-color;
            &:before {
              color: lighten($sidebar-dark-submenu-color, 10%);
            }
            &.active {
              color: $sidebar-dark-menu-active-color;
              background: transparent;
            }
            &:hover {
              color: $sidebar-dark-submenu-hover-color;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}
////////// SIDEBAR ////////
$sidebar-width-lg: 255px;
$sidebar-width-mini: 232px;
$sidebar-width-icon: 70px;
$sidebar-default-font-size:0.875rem;

$sidebar-light-bg: #f8f8f8;
$sidebar-light-menu-color: #001737;
$sidebar-light-submenu-color: #656565;
$sidebar-light-menu-active-bg: initial;
$sidebar-light-menu-active-color: #000;
$sidebar-light-menu-hover-bg: transparent;
$sidebar-light-menu-hover-color: #56595a;
$sidebar-light-submenu-hover-color: $black;
$sidebar-light-submenu-active-color: #000;
$sidebar-light-menu-icon-color: theme-color(primary);
$sidebar-light-menu-arrow-color: #bdc0f3;
$sidebar-light-profile-name-color: #404852;
$sidebar-light-profile-title-color: #8d9498;
$sidebar-light-dropdown-arrow-color:#bdc0f3;
$sidebar-light-menu-title-color:#2f3cff;
$sidebar-light-profile-name-color: #001737; 
$sidebar-light-icon-title: #2f3cff;

$sidebar-dark-bg: #26293b;
$sidebar-dark-menu-color: #8695a7;
$sidebar-dark-menu-active-bg: initial;
$sidebar-dark-menu-active-color: #fff;
$sidebar-dark-menu-hover-bg: #59606b;
$sidebar-dark-menu-hover-color: #fff;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #9a94a7;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-profile-name-color: #fff;
$sidebar-dark-dropdown-arrow-color:#bdc0f3;
$sidebar-dark-icon-title: #fff;
$sidebar-dark-menu-title-color:#fff;

$sidebar-menu-font-size: .875rem;
$sidebar-menu-padding-left: 2.187rem;
$sidebar-menu-padding-right: 2.187rem;
$sidebar-menu-padding-top: .625rem;
$sidebar-menu-padding-bottom: .625rem;

$sidebar-profile-height:87px;

$sidebar-submenu-padding: .25rem 0 0 2.187rem;
$sidebar-submenu-font-size: .875rem;
$sidebar-submenu-item-padding: .625rem 1rem;

$sidebar-icon-font-size:  1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$sidebar-box-shadow: 6px 16px 31px -18px #b7bcd1;

$rtl-sidebar-submenu-padding: 0 4.5rem 0 0;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #e9ecef;
$icon-only-menu-bg-dark: $sidebar-dark-bg;

///////// SIDEBAR ////////

///////// BOXED LAYOUT ///////
$boxed-container-width: 1200px;
$boxed-layout-bg: #c6c8ca;
///////// BOXED LAYOUT ///////

///////// SETTINGS PANEL ////////

$settings-panel-width: 300px;

///////// NAVBAR ////////

$navbar-default-bg: #f8f8f8;
$navbar-height: 60px;
$navbar-menu-color: $white;
$navbar-font-size: 0.875rem;
$navbar-icon-font-size: .9375rem;
$navbar-brand-bg: #f8f8f8;
$navbar-search-color: #001737;
$navbar-color: #001737;
$navbar-dropdown-arrow-color:#bdc0f3; 
$dropdown-bg: #464dee;
$dropdown-color: #fff;
$profile-dropdown-hover-bg:#f0f3f6;

///////// NAVBAR ////////
//Badge Mixins

// Badge outlined variations
@mixin badge-outline-variations($color) {
	color: $color;
	border: 1px solid $color;
}
@mixin badge-opacity-variations($color) {
	background: lighten($color, 30%);
	color: darken($color, 10%);
	border: 1px solid lighten($color, 30%); 
}
/* Bootstrap Progress */

.progress {
  @include border-radius(2px);
  height: 8px;
  .progress-bar {
    @include border-radius(2px);
  }

  &.progress-sm{
    height: 0.375rem;
  }
  &.progress-md {
    height: 8px;
  }
  &.progress-lg {
    height: 15px;
  }
  &.progress-xl {
    height: 18px;
  }
  &.grouped {
    height: 35px;
    .progress-bar {
      @include border-radius(0px);
    }
  }
}